import $ from 'jquery';
import '@popperjs/core';
import 'bootstrap';
import 'bootstrap/js/src/util';
import SmoothScroll from 'smooth-scroll';
import './assets/js/canva-animation';
//import 'canvas-particle-network';

import WebFont from "webfontloader";
WebFont.load({
  google: {
    families: ["Roboto:300,400,500", "Nunito:300,400,500,700", "Varela Round: 300,400,500,700"]
  },
  custom: {
    families: ['Font Awesome\ 5 Icons:400,900', 'Font Awesome\ 5 Brands:400'],
    urls: ['//use.fontawesome.com/releases/v5.0.8/css/all.css']
  }
});

import './assets/scss/main.scss'

function checkScroll() {
  const scroll = $(window).scrollTop();
  if (scroll > 150) {
    $("#nav-menu").addClass('menu-hide')
    $("#nav-menu-fixed").addClass('menu-show')
  } else {
    $("#nav-menu").removeClass('menu-hide')
    $("#nav-menu-fixed").removeClass('menu-show')
    $('#navbarNavAltMarkupFixed').removeClass('show')
  }
}
$(document).ready(() => {
  let scroll = new SmoothScroll(
      '#nav-menu a[href*="#"], #nav-menu-fixed a[href*="#"], #menu-logo',
      {offset: 84, durationMax: 200, easing: 'Linear', speed: 200}
  )
  
  $(window).scroll(function (event) {
    checkScroll()
  });
  checkScroll()

  function log(obj) {
    console.log(obj)
  }

  $('#navbarNavAltMarkup a, #navbarNavAltMarkupFixed a').click((e) => {
    $('#navbarNavAltMarkup').removeClass('show')
    $('#navbarNavAltMarkupFixed').removeClass('show')
  })
})
